import { graphql } from 'gatsby';
import React from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import { Odin } from '../src/components/Odin/Odin';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Uthred from '../src/components/Uthred/Uthred';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import Icon from '../src/helpers/icon';
import materialGrafico from '../src/icons/material-grafico';
import SEO from '../src/helpers/seo';

import './material_grafico_halloween.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query materialHalloweenQuery($locale: String!) {
    allMaterialHalloweenSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allMaterialHalloweenTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allMaterialHalloweenImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMaterialHalloweenBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allMaterialHalloweenOdin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allMaterialHalloweenImageList(filter: { lang: { eq: "es" } }) {
      edges {
        node {
          imageArr
        }
      }
    }
    allMaterialHalloweenPopUp(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
          ctaText
        }
      }
    }
    allMaterialHalloweenRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class MaterialHalloween extends React.Component {
  state = {};

  retrieveImageList = array =>
    array.map(e => (
      <div className="ma-photo">
        <img src={e} alt="img" />
        <div className="photo-icon">
          <a href={e} download>
            <Icon
              icon="get_app"
              width="30"
              height="30"
              class="download-icon"
              iconSet={materialGrafico}
            />
          </a>
        </div>
      </div>
    ));

  render() {
    const heimdallData = {
      name: this.props.data.allMaterialHalloweenTitle.edges[0].node.title,
      image: {
        url: this.props.data.allMaterialHalloweenImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const odinData = {
      image: this.props.data.allMaterialHalloweenImageList.edges[0].node.imageArr[0],
      alt: 'GALERIA HALLOWEEN',
      title: tt(
        '¿Quieres ver todo lo que te espera en el Halloween más terrorífico de PortAventura Park? ',
        this.props.pageContext.locale
      ).toUpperCase(),
      rssImg: true,
      buttons: {
        cta1: this.props.data.allMaterialHalloweenOdin.edges[0].node.cta,
        ctaText1: this.props.data.allMaterialHalloweenOdin.edges[0].node.ctaText,
        size: 'alone',
        color1: 'orange',
      },
    };
    const h2Data = {
      title: this.props.data.allMaterialHalloweenTitle.edges[0].node.description,
    };

    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allMaterialHalloweenSeoBlock.edges[0].node._0.title}
          description={this.props.data.allMaterialHalloweenSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <Uthred data={this.props.data.allMaterialHalloweenPopUp.edges[0].node} />
          <div className="general-index">
            <div className="graphic-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allMaterialHalloweenBreadcrumbBlock.edges[0].node.name}
                options={this.props.pageContext.options}
              />
              <Hod
                data={this.props.data.allMaterialHalloweenRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allMaterialHalloweenImageHeaderBlock.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div className="gallery-photos">
                {this.retrieveImageList(
                  this.props.data.allMaterialHalloweenImageList.edges[0].node.imageArr
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MaterialHalloween;
